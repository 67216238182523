<template>
  <div class="wrap">
    <nav-bar></nav-bar>
    <el-dialog title="写私信" :visible.sync="dialogFormVisible">
      <el-form :model="dialogForm">
        <el-form-item>
          <el-input v-model="detailInfo.nickName" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="textarea" v-model="dialogForm.privateLetter" placeholder="" rows="4" maxlength="600"
            resize="none" show-word-limit>
          </el-input>
        </el-form-item>
        <div class="add-expression">
          <a href="javascript:void(0);">
            <i></i>
            {{$t("twoDimensionalProgrammeAddExpression")}}
          </a>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="warning" @click="sendPrivateLetter">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="dialogForm.replayTitle" :visible.sync="commentReplayVisible">
      <el-form :model="dialogForm">
        <el-form-item>
          <el-input type="textarea" v-model="dialogForm.reportContents" placeholder="请输入举报原因!" rows="4" resize="none"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="commentReplayVisible = false">取 消</el-button>
        <el-button type="warning" @click="sendReportComment">确 定</el-button>
      </div>
    </el-dialog>
    <div :class="{'hasH': isFixed}"></div>
    <div class="scheme-release-info" :class="{'is-fixed': isFixed}"  ref="releaseInfo">
      <div class="info-inner-wrap">
        <b-row>
          <b-col class="info-left" xs="12" sm="12" md="7" lg="6" xl="4">
            <h1 class="info-title">
              {{detailInfo.name}}
              <span>{{projectType}}</span>
            </h1>
            <div class="release-time">{{releaseTime}}前发布</div>
            <div class="params-wrap">
              <span>{{$t("twoDimensionalProgrammeOriginalDesign")}}</span>
              <span>{{projectType.slice(0,2)}}</span>
              <span>{{projectStyle}}</span>
            </div>
          </b-col>
          <b-col class="info-right-second ml-auto order-xs-2" order-sm="2" order-md="2" order-lg="2" order-xl="1" sm="6" md="12"
            lg="6" xl="3">
            <div class="info-right-inner">
              <div class="item">
                <i></i>
                <span>{{detailInfo.viewNum}}</span>
              </div>
              <div class="item">
                <i></i>
                <span>{{collectTotal}}</span>
              </div>
              <div class="item">
                <i></i>
                <span>{{commentData.commentNumber || 0}}</span>
              </div>
              <div class="item">
                <i class="liked" @click="liked" ></i>
                <span>{{detailInfo.likedNumber || 0}}</span>
              </div>
            </div>
          </b-col>
          <b-col class="info-right-first order-xs-1" order-sm="1" order-md="1" order-lg="1" order-xl="2" sm="6" md="5" lg="6"
            xl="3">
            <!-- <div class="right-inner-wrap">
              <div class="avatar">
                <img :src="detailInfo.headImgUri" alt="">
              </div>
              <div class="right">
                <div class="name">{{detailInfo.nickName}}</div>
                <div class="items-wrap">
                  <span>{{detailInfo.city}}</span>
                  <span>家居导购/销售 </span>
                </div>
                <div class="btn-wrap">
                  <div class="follow-btn" @click="follow">
                    {{detailInfo.followStatus ? $t("designerFollow") : $t("twoDimensionalProgrammeFollow")}}
                  </div>
                  <div class="private-btn" @click="openPrivateLetterDialog">
                    {{$t("twoDimensionalProgrammePrivateLetter")}}
                  </div>
                </div>
              </div>
            </div> -->
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="content-wrap">
      <b-row>
        <b-col class="left-wrap" sm="12" md="12" lg="9" xl="8">
          <div class="left-con-wrap">
            <div class="banner-wrap" v-if="detailInfo.designsJson">
              <div class="pic-wrap" v-for="(item, index) in detailInfo.designsJson[0].designsJsonV2" :key="index">
                <img :src="item.designCoverUrl" alt="">
              </div>
              <!-- <img src="@/../public/icon/twoDimensionalProgramme/20210709145054.png" alt=""> -->
            </div>
            <div class="no-data-wrap"
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-background="rgba(255, 255, 255, 0.8)"
            v-else></div>
            <div class="btn-wrap" :class="{hash: btnIsFixed || btnIsFixedTop}" ref="btnWrap">
              <div class="btn-wrap-inner" :class="{'btn-fixed': btnIsFixed, 'btn-fixed-top': btnIsFixedTop}">
                <!-- <div class="reproduce btn" @click="reproduce">
                  {{$t("twoDimensionalProgrammeReproduce")}}
                  <span>({{detailInfo.type == 3 ? '39' : '199'}} e币)</span>
                </div> -->
                <div class="collection btn" @click="collection">
                  <i :class="detailInfo.collectStatus ? 'el-icon-star-on is-collection' : 'el-icon-star-on'"></i>
                  {{detailInfo.collectStatus ? $t("twoDimensionalProgrammeIsCollection") : $t("twoDimensionalProgrammeCollection")}}
                </div>
              </div>
            </div>
            <div class="comment-area">
              <div class="comment-release-area">
                <el-form :model="form">
                  <el-form-item prop="comment">
                    <el-input type="textarea" v-model="form.comment"
                      :placeholder="$t('twoDimensionalProgrammePlaceholder')" maxlength="2000" show-word-limit>
                    </el-input>
                  </el-form-item>
                  <div class="operation-wrap">
                    <div class="add-expression">
                      <a href="javascript:void(0);">
                        <i></i>
                        {{$t("twoDimensionalProgrammeAddExpression")}}
                      </a>
                    </div>
                    <div class="send-comment" @click="sendComment">{{$t("twoDimensionalProgrammeComment")}}</div>
                  </div>
                </el-form>
              </div>
              <div class="user-comment-con" v-if="commentData.commentNumber > 0">
                <div class="comment-count">
                  {{$t("twoDimensionalProgrammeAllComments")}}
                  <span>{{commentData.commentNumber}}</span>
                </div>
                <div class="comment-item-wrap" v-for="(item, index) in commentData.comments" :key="index">
                  <div class="comment-user">
                    <div class="avatat">
                      <img :src="item.imgUrl || require('@/../public/icon/user-default-avatar.jpg')" :alt="item.name">
                    </div>
                  </div>
                  <div class="comment-info">
                    <div class="comment-user-name">{{item.name}}</div>
                    <div class="comment-content">
                      <p>{{item.comments.commentContents}}</p>
                    </div>
                    <div class="operation-wrap">
                      <div class="comment-time">{{item.timeSub}}</div>
                      <div class="operation-item-wrap">
                        <div class="item">
                          <a href="javascript:void(0);" @click="reportComment(item, 1)">
                            <i></i>{{$t("twoDimensionalProgrammeReport")}}
                          </a>
                        </div>
                        <div class="item">
                          <a href="javascript:void(0);" @click="openCommentReply(item, 1, index)">
                            <i></i>
                          </a>
                        </div>
                        <div class="item">
                          <a href="javascript:void(0);" @click="commentThumbsUp(item, 1)">
                            <i></i>{{item.comments.thumbsUp}}
                          </a>
                        </div>
                      </div>
                    </div>
                    
                    <div class="comment-reply" v-if="item.replays.length > 0">
                      <i class="arrow"></i>
                      <div class="comment-reply-con" v-for="(i, idx) in item.replays" :key="idx">
                        <div class="comment-user">
                          <div class="avatat">
                            <img :src="i.imgUrl" :alt="i.name">
                          </div>
                        </div>
                        <div class="comment-info">
                          <div class="comment-user-name">{{i.name}}</div>
                          <div class="reply">
                            <span>{{$t("twoDimensionalProgrammeReply")}}</span>
                            <div class="comment-user-name">{{i.toName}}:</div>
                            <div class="comment-content">
                              <p>{{i.replays.replayContents}}</p>
                            </div>
                          </div>
                          <div class="operation-wrap">
                            <div class="comment-time">{{i.timeSub}}</div>
                            <div class="operation-item-wrap">
                              <div class="item">
                                <a href="javascript:void(0);" @click="reportComment(i, 2)">
                                  <i></i>{{$t("twoDimensionalProgrammeReport")}}
                                </a>
                              </div>
                              <div class="item">
                                <a href="javascript:void(0);" @click="openCommentReply(i, 2, index)">
                                  <i></i>
                                </a>
                              </div>
                              <div class="item">
                                <a href="javascript:void(0);" @click="commentThumbsUp(i, 2)">
                                  <i></i>{{i.replays.thumbsUp}}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="edit-comment-reply" v-if="commentData.comments[index].commentReplyOpen">
                      <el-form :model="form">
                        <el-form-item prop="comment">
                          <el-input type="textarea" v-model="form.commentReply" :placeholder="'回复'+ form.commentUser" maxlength="2000"
                            show-word-limit rows="1"></el-input>
                        </el-form-item>
                        <div class="operation-wrap">
                          <div class="add-expression">
                            <a href="javascript:void(0);">
                              <i></i>
                              {{$t("twoDimensionalProgrammeAddExpression")}}
                            </a>
                          </div>
                          <div class="send-comment" @click="commentReply(index)">{{$t("twoDimensionalProgrammeReply")}}</div>
                        </div>
                      </el-form>
                    </div>
                    
                  </div>
                </div>

                <div class="look-more-comment-wrap">
                  <a href="javascript:void(0)">{{$t("twoDimensionalProgrammeSeeMoreComments")}}</a>
                </div>
              </div>
              <div class="comment-empty-wrap" v-else>
                <div class="comment-empty-con">
                  <div class="comment-empty-pic">
                    <img src="@/../public/icon/twoDimensionalProgramme/comment-empty@2x.png" alt="">
                  </div>
                  <p>{{$t("twoDimensionalProgrammeCommentEmpty")}}</p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="right-wrap" sm="12" md="12" lg="3" xl="4">
          <div class="right-con-wrap">
            <el-divider content-position="center">{{$t("twoDimensionalProgrammeItemsIncludedInTheProgram")}}
            </el-divider>
            <div class="product-list" v-if="productItems.length > 0">
              <b-row>
                <b-col class="item" sm="6" md="4" lg="12" xl="6" v-for="(item, index) in productItems" :key="index"
                  @click="toDetail(index)">
                  <div class="item-inner">
                    <div class="pic">
                      <a href="javascript:void(0);">
                        <img :src="item.file_path" :alt="item.goods_name">
                        <i class="collection" @click.stop="itemCollection(index)">
                          <img v-if="item.collection_status" src="@/../public/icon/vipPrivilege/is-collection@2x.png"
                            alt="">
                          <img v-else src="@/../public/icon/vipPrivilege/collection@2x.png" alt="">
                          <span
                            class="tip-label">{{item.collection_status ? $t("twoDimensionalProgrammeCancel") : $t("twoDimensionalProgrammeCollection")}}</span>
                        </i>
                      </a>
                    </div>
                    <div class="product-detail">
                      <div class="product-name">
                        <a href="javascript:void(0);">{{item.goods_name}}</a>
                      </div>
                      <div class="price">￥ {{item.sale_price}}</div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="no-single-item" v-else>
              <div class="no-single-item-pic">
                <img src="@/../public/icon/twoDimensionalProgramme/no-single-item@2x.png" alt="">
              </div>
              <p>{{$t("twoDimensionalProgrammeNoSingleItem")}}</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/Navbar.vue";
  import dump from "@/util/dump.js";
  import url from "@/api/config";
  import epeidaUrl from "@/api/epeidaUrlConfig";
  export default {
    components: {
      NavBar
    },
    data() {
      return {
        form: {
          comment: "",
          commentReply: "",
          commentUser: "",
        },
        productItems: [],
        detailInfo: [],
        dialogFormVisible: false,
        commentReplayVisible: false,
        dialogForm: {
          privateLetter: "",
          reportContents: "",
          replayTitle: ""
        },
        projectId: "",
        commentData: [],
        replayType: 1,
        // 作品收藏总数
        collectTotal: 0,
        // 是否已对作者点赞 0未点赞 1已点赞
        hasLike: 0,
        // 被评论者ID
        fromUserId: "",
        // 回复目标ID
        targetId: "",
        // 被举报评论的ID
        reportCommentId: "",
        // 被举报评论的类型 0:评论 1:回复
        reportType: 0,
        releaseTime: "",
        roleId: "",
        isBuy: 0,
        canvasAmount: "",
        isFixed: false,
        offsetTop: 0,
        btnIsFixed: false,
        btnIsFixedTop: false,
        loading: true
      }
    },
    created() {
      this.init();
    },
    mounted() {
      window.addEventListener('scroll',this.initHeight);
      this.$nextTick(() => {
        // console.log(this.$refs.releaseInfo.offsetTop)
        // this.offsetTop = this.$refs.releaseInfo.offsetTop;
        // console.log(this.$refs.btnWrap.offsetTop)
        setTimeout(()=>{
          let screenHeight = document.documentElement.clientHeight || document.body.clientHeight;
          this.btnIsFixed = this.$refs.btnWrap.offsetTop > (screenHeight-this.$refs.releaseInfo.offsetTop-this.$refs.releaseInfo.offsetHeight-this.$refs.btnWrap.clientHeight) ? true : false;
        },1000)
      })
    },
    destroyed(){
      window.removeEventListener("scroll", this.initHeight, false);
    },
    computed: {
      projectType(){
        let type = "";
        switch(this.detailInfo.type){
          case 1 : type = "公装方案";
          break;
          case 2 : type = "家装方案";
          break;
          case 3 : type = "拼图方案";
          break;
          case 4 : type = "套餐方案";
          break;
          case -1 : type = "其它方案";
          break;
        }
        return type;
      },
      projectStyle(){
        let style = "";
        switch(this.detailInfo.style){
          case 1 : style = "极简";
          break;
          case 2 : style = "轻奢";
          break;
          case 3 : style = "中式";
          break;
          case 4 : style = "美式";
          break;
          case 5 : style = "北欧/现代";
          break;
          case 6 : style = "英式";
          break;
          case 7 : style = "欧式/新古典";
          break;
        }
        return style;
      }
    },
    methods: {
      init() {
        let itemIdArr = [],
            itemId = "";
        const token = localStorage.getItem("token");
        this.roleId = localStorage.getItem("role_id");
        this.projectId = this.$route.query.id;
        this.$api.design2DDetail({
          projectId: this.projectId,
          token
        }).then(res => {
          if (res.code === 200) {
            
            this.detailInfo = res.data.designInfo;
            // 作品收藏总数
            this.collectTotal = res.data.collectNum;
            // 是否已对作者点赞 0未点赞 1已点赞
            this.hasLike = res.data.hasLike;
            
            // 作品发布时间
            this.releaseTime = res.data.timeSub;
            // 用户是否已购买该作品
            this.isBuy = res.data.isBuy;
            
            this.canvasAmount = res.data.canvasAmount;
            
            this.detailInfo.designsJson[0].designsJsonV2.forEach(item => {
              // 把每个产品的content数据转换为json对象
              let contentObj = [];
              contentObj.push(JSON.parse(item.content));
              // 循环contentObj数组取每项的objects
              contentObj.forEach(item => {
                // 循环objects取每项type类型为image，并且ID不为空的ID值。
                item.objects.forEach(i => {
                  if(i.type === "image" && i.extendMsg.id && i.extendMsg.type =='product'){
                    itemIdArr.push(i.extendMsg.id);
                  }
                })
              })
            });
            
            itemId = Array.from(new Set(itemIdArr)).join(",");

            this.$api.design2DItem({
              goods_list: itemId
            }).then(res => {
              // console.log(res)
              if (res.status === 100) {
                res.data.forEach((item) => {
                  item.file_path = item.file_path.split(",")[0];
                })
                this.productItems = res.data;
              }
            }).catch(err => console.error(err));
            
            // 获取二维方案的评论
            this.getComment(this.detailInfo.id);
          }
        }).catch(err => console.error(err));
        
      },
      initHeight(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        let screenHeight = document.documentElement.clientHeight || document.body.clientHeight;
        // this.isFixed = scrollTop > this.offsetTop ? true : false;
        this.btnIsFixed = scrollTop + (screenHeight-this.$refs.releaseInfo.offsetTop-this.$refs.releaseInfo.offsetHeight-this.$refs.btnWrap.clientHeight) >= this.$refs.btnWrap.offsetTop ? false : true;
        if(scrollTop > this.$refs.btnWrap.offsetTop){
          this.btnIsFixedTop = true;
        }else{
          this.btnIsFixedTop = false;
        }
      },
      // 关注
      follow() {
        if (!dump.checkLogin()) {
          return false;
        }
        // let showMsg;
        let flag;
        if (this.detailInfo.followStatus == 1) {
          // showMsg = this.$t("designerFollowOut");
          flag = 2;
        } else {
          // showMsg = this.$t("designerFollowIn");
          flag = 1;
        }
        let data = {
          flag,
          targetMemberId: this.detailInfo.memberId
        };
        this.$api.followDesigner(data).then(res => {
          if (res.code == 200) {
            if (flag == 1) {
              this.detailInfo.followStatus = 1;
            } else {
              this.detailInfo.followStatus = 0;
            }
          }
        })
      },
      // 打开发私信窗口
      openPrivateLetterDialog() {
        this.dialogFormVisible = !this.dialogFormVisible;
      },
      // 发私信
      sendPrivateLetter() {
        if (!dump.checkLogin()) {
          return false;
        }
        if (this.dialogForm.privateLetter.trim() === "") {
          this.$message({
            message: '私信内容不能为空！',
            type: 'warning'
          });
          return false;
        }
        this.dialogFormVisible = false;
        this.$api.sendPrivateLetter({
          projectType: 4,
          token: localStorage.getItem("token"),
          toUserPhone: this.detailInfo.phone,
          commentContents: this.dialogForm.privateLetter.trim()
        }).then(res => {
          if (res.status === 100) {
            this.$alert('私信发送成功！', '温馨提示', {
              confirmButtonText: '确定',
              callback: () => {
                this.dialogForm.privateLetter = "";
              }
            });
          } else {
            this.$alert('私信发送失败！', '温馨提示', {
              confirmButtonText: '确定'
            });
          }
        }).catch(err => console.error(err));
      },
      // 再创作
      reproduce() {
        // 检测用户是否登录
        if (!dump.checkLogin()) {
          return false;
        }
        let eb = this.detailInfo.type == 3 ? 39 : 199;
        
        // 判断用户是否已购买该作品
        // if(this.isBuy == 1){
        //   return this.toReproduce();
        // }
        

        // de9de2f006e145a29d52dfadda295353 运营商
        // 38e045588e0e4ce19b80ca40f5325934 高级会员 = 合伙人 = 分销商
        // 如果role_id == 4 || role_id == de9de2f006e145a29d52dfadda295353 直接进入再创作
        if(this.roleId == 4 || this.roleId == 'de9de2f006e145a29d52dfadda295353' || this.roleId == '38e045588e0e4ce19b80ca40f5325934'){
          this.$message({
            message: '尊敬的高级会员您拥有该作品无限免费再创作的权利',
            type: 'success'
          });
          setTimeout(() => {
            this.toReproduce();
          },1000);
        }else{
          
          // 查询用户套餐资源
          this.$api.getUsable({
            token: localStorage.getItem("token")
          }).then(res => {
            if(res.status === 100){
              let identity = "";
              switch(this.roleId){
                case '3' : identity = "注册用户";
                break;
                case '5' : identity = "季会员";
                break;
                case '6' : identity = "年会员";
                break;
              }
              
              let resource = {};
              res.data.forEach(item => {
                if(item.resourceName == '二维方案再创作'){
                  resource = item;
                }
              })
              
              // resource.resourceRemain > 0 用户还有套餐使用量
              if(resource.resourceRemain > 0){
                this.$alert(`尊敬的${identity}您还有${resource.resourceRemain}次${this.projectType}再创作使用量`, '温馨提示', {
                  confirmButtonText: '确定',
                  callback: val => {
                    // 用户取消
                    if(val == 'cancel') return;
                    this.toReproduce();
                  }
                });
              }else{
                // 用户套餐使用量已用完；查看用户是否有e币可以使用购买该作品 
                this.$alert(`尊敬的${identity}您的${this.projectType}再创作免费套餐使用量已用完，是否通过花费${eb}e币购买该作品再创作权限？`, '温馨提示', {
                  confirmButtonText: '确定',
                  callback: val => {
                    // 用户取消
                    if(val == 'cancel') return;
                    
                    this.$api.getUserWallet({
                      token: localStorage.getItem("token")
                    }).then(res => {
                      if(res.status === 100){
                        if(res.data.balance >= eb){
                          // 使用e币购买
                          // -2:二维方案 -3:拼图 -4:3D模型
                          this.$api.payForDesignerWork({
                            // this.detailInfo.type == 3 为拼图方案 其它的都为套餐
                            type: this.detailInfo.type == 3 ? -3 : -2,
                            token: localStorage.getItem("token"),
                            fromPhone: this.detailInfo.phone,
                            id: this.detailInfo.id,
                            num: this.canvasAmount
                          }).then(res => {
                            if(res.status === 100){
                              this.toReproduce();
                            }
                          }).catch(err => console.error(err));
                        }else{
                          // e币不足以购买该作品跳转到购买VIP套餐页
                          this.$confirm(`您的e币不足，是否前往VIP特权页面购买套餐？`, '温馨提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                          }).then(() => {
                            this.toVipPrivilege();
                          }).catch(() => {});
                        }
                      }
                    }).catch(err => console.error(err));
                  }
                });
              }
            }
          }).catch(err => console.error(err));
        }
      },
      // 跳转到方案再创作
      toReproduce(){
        const params = {
          type: 2,
          link: `${url}/mapp/plan/toRecreate?url=${epeidaUrl}&project_id=${this.projectId}&fromPhone=${this.detailInfo.phone}&num=${this.canvasAmount}&type=${this.detailInfo.type}&token=${localStorage.getItem("token")}`
        }
        dump.link(params);
      },
      // 跳转到VIP特权套餐页面
      toVipPrivilege(){
        const params = {
          type: 3,
          link: "/vipprivilege"
        }
        dump.link(params);
      },
      // 二维方案收藏
      collection() {
        if (!dump.checkLogin()) {
          return false;
        }
        const token = localStorage.getItem("token");
        let flag = 1;
        // 判断产品是否已收藏
        if (this.detailInfo.collectStatus) {
          flag = 2;
        }

        this.$api.design2DCollection({
          colId: this.projectId,
          colType: this.detailInfo.colType || 1,
          flag,
          token
        }).then(res => {
          if (res.code === 200) {
            if (flag === 1) {
              this.detailInfo.collectStatus = 1;
            } else if (flag === 2) {
              this.detailInfo.collectStatus = 0;
            }
          }
        }).catch(err => console.error(err));
      },
      // 方案单品收藏
      itemCollection(i) {
        if (!dump.checkLogin()) {
          return false;
        }

        let flag = 0;
        if (this.productItems[i].collection_status == 1) {
          flag = 1;
        }
        this.$api.goodsItemCollection({
          // 1商品 2品牌 3模型
          collection_type: "1",
          project_id: this.productItems[i].goods_id,
          token: localStorage.getItem("token")
        }).then(res => {
          if (res.status === 100) {
            if (flag) {
              this.productItems[i].collection_status = 0;
            } else {
              this.productItems[i].collection_status = 1;
            }
          }
        }).catch(err => console.error(err))
      },
      // 点击方案单品到方案单品详情
      toDetail(i) {
        dump.link({
          type: 2,
          link: "/product/details?good_id=" + this.productItems[i].goods_id
        })
      },
      // 发表评论
      sendComment(){
        if (!dump.checkLogin()) {
          return false;
        }
        if(this.form.comment.trim() === ""){
          this.$message({
            message: '评论内容不能为空！',
            type: 'warning'
          });
          return false;
        }
        
        this.$api.sendPrivateLetter({
          // 二维方案ID/3D模型ID/套餐ID/设计师用户ID,非必填,以后也可能是其他ID
          projectId: this.detailInfo.id,
          // 1 二维方案 2 3D模型 3 套餐 4 设计师私信
          projectType: "1",
          token: localStorage.getItem("token"),
          toUserPhone: this.detailInfo.phone,
          commentContents: this.form.comment.trim()
        }).then(res => {
          if(res.status === 100){
            //this.getComment(this.detailInfo.id);
            this.form.comment = "";
            this.$alert('评论成功！', '温馨提示', {
              confirmButtonText: '确定',
              callback: () => {
                this.getComment(this.detailInfo.id);
              }
            });
          }
        }).catch(err => console.error(err));
        
      },
      // 获取二维方案的评论
      getComment(id){
        this.$api.getCommentItems({
          // 二维方案ID/3D模型ID/套餐ID/用户ID,非必填,以后也可能是其他ID
          projectId: id,
          // projectType: 1 二维方案 2 3D模型 3 套餐 4 设计师私信
          projectType: 1
        }).then(res => {
          if(res.status === 100){
            this.commentData = res.data;
            if(!this.commentData.comments) return;
            this.commentData.comments.forEach(item => {
              this.$set(item, 'commentReplyOpen', false);
            });
          }
        }).catch(err => console.error(err));
      },
      // 打开评论回复
      openCommentReply(obj, type, i){
        //obj.commentReplyOpen = !obj.commentReplyOpen;
        this.commentData.comments[i].commentReplyOpen = !this.commentData.comments[i].commentReplyOpen;
        this.form.commentUser = obj.name;
        this.replayType = type;
        this.targetId = type === 1 ? obj.comments.commentId : obj.replays.replayId;
        // 被评论者ID
        this.fromUserId = type === 1 ? obj.comments.fromUserId : obj.replays.fromUserId;
        console.log(this.fromUserId);
      },
      // 评论回复
      commentReply(i){
        if (!dump.checkLogin()) {
          return false;
        }
        if(this.form.commentReply.trim() === ""){
          this.$message({
            message: '评论回复内容不能为空！',
            type: 'warning'
          });
          return false;
        }
        
        // this.commentReplyOpen = false;
        this.commentData.comments[i].commentReplyOpen = false;
        this.$api.commentReply({
          // 回复目标评论ID
          replayTargetId: this.targetId,
          // 回复评论类型
          replayType: this.replayType,
          token: localStorage.getItem("token"),
          // 评论信息
          replayContents: this.form.commentReply,
          // 被评论者ID
          toUserId: this.fromUserId
        }).then(res => {
          if(res.status === 100){
            this.form.commentReply = "";
            this.$alert('评论回复成功！', '温馨提示', {
              confirmButtonText: '确定',
              callback: () => {
                this.getComment(this.detailInfo.id);
              }
            });
          }
        }).catch(err => console.error(err));
      },
      // 评论点赞
      commentThumbsUp(obj, type){
        // type 1评论点赞 2回复评论点赞
        let flag = 1;
        // 判断点赞还在取消点赞
        if(obj.thumbsUpStatus){
          flag = 0;
        }
        this.$api.commentThumbsUp({
          // 评论ID/回复ID
          id: type === 1 ? obj.comments.commentId : obj.replays.replayId,
          token: localStorage.getItem("token"),
          // 0 对评论者的评论点赞 1 对回复者的评论点赞
          idType: type === 1 ? 0 : 1,
          // 0:取消点赞  1:点赞
          flag
        }).then(res => {
          if(res.status === 100){
            this.getComment(this.detailInfo.id);
          }

        }).catch(err => console.error(err));
      },
      // 打开评论举报窗口
      reportComment(obj, type){
        // type 1 对评论举报 2对评论的回复举报
        this.reportCommentId = type === 1 ? obj.comments.commentId : obj.replays.replayId;
        this.dialogForm.replayTitle = type === 1 ? '评论内容举报' : '评论回复内容举报';
        this.reportType = type === 1 ? 0 : 1;
        this.commentReplayVisible = !this.commentReplayVisible;
      },
      // 提交评论举报内容
      sendReportComment(){
        if (!dump.checkLogin()) {
          return false;
        }
        if (this.dialogForm.reportContents.trim() === "") {
          this.$message({
            message: '举报原因不能为空！',
            type: 'warning'
          });
          return false;
        }
        this.commentReplayVisible = false;
        this.$api.commentReport({
          token: localStorage.getItem("token"),
          id: this.reportCommentId,
          // 0:评论 1:回复
          first: this.reportType,
          reportContents: this.dialogForm.reportContents
        }).then(res => {
          if(res.status === 100){
            this.dialogForm.reportContents = "";
            this.$alert(res.msg, '温馨提示', {
              confirmButtonText: '确定'
            });
          }
        }).catch(err => console.error(err));
      },
      // 对作者点赞
      liked(){
        this.$api.designLiked({
          token: localStorage.getItem("token"),
          targetId: this.detailInfo.id,
          // flag=0,取消点赞; flag=1,点赞
          flag: this.hasLike ? 0 : 1
        }).then(res => {
          if(res.code === 200){
            this.init();
          }
        }).catch(err => console.error(err));
      }
    }

  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/view/two-dimensional-programme/twoDimensionalProgramme";
</style>
